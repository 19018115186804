<template>
  <div class="m-4  d-flex">
    <div class="col-sm-12">
      <h3 class="fs-3 fw-bold  pb-4 border-b-sm">새 주문 추가</h3>
      <form @submit.prevent="addOrder" class="space-y-4 col-sm-6">
        <div class="row g-3">
          <div class="col-md-12">
            <div class="form-group d-flex justify-content-between align-items-center">
              <label class="col-3 form-label mb-0">납품지</label>
              <input v-model="newOrder.delivery_location" type="text" class="form-control ms-3" required />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group d-flex justify-content-between align-items-center">
              <label class="col-3 form-label mb-0">장비 종류</label>
              <div class="ms-3 w-100">
                <input v-model="newOrder.production_type" list="productionTypeOptions" class="form-control" required />
                <datalist id="productionTypeOptions">
                  <option v-for="type in productionTypes" :key="type" :value="type">{{ type }}</option>
                </datalist>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group d-flex justify-content-between align-items-center">
              <label class="col-3 form-label mb-0">주문작성자</label>
              <input v-model="newOrder.responsible_person" list="confrimNameList" type="text" class="form-control ms-3"
                required />
              <datalist id="confrimNameList">
                <option v-for="item in useLogin.memberList" :value="item" :key="item"></option>
              </datalist>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group d-flex justify-content-between align-items-center">
              <label class="col-3 form-label mb-0">필요 생산 수</label>
              <input v-model="newOrder.production_quantity" type="number" class="form-control ms-3" required />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group d-flex justify-content-between align-items-center">
              <label class="col-3 form-label mb-0">주문 일시</label>
              <input v-model="newOrder.order_date" type="date" class="form-control ms-3" required />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group d-flex justify-content-between align-items-center">
              <label class="col-3 form-label mb-0">주문 마감일</label>
              <input v-model="newOrder.order_deadline" type="date" class="form-control ms-3" required />
            </div>
          </div>
        </div>
        <div class="mt-4 text-center">
          <button type="submit" class="btn btn-success px-6 py-2 rounded-lg shadow-sm text-white">
            추가
          </button>
        </div>
      </form>
    </div>
  </div>


</template>


<script setup>
  import { onMounted, reactive, watch } from 'vue';
  import dayjs from 'dayjs';
  import { useOrderStore } from '@/stores/order.js';
  import { useLoginStore } from '@/stores/login';
  const useLogin = useLoginStore();
  const deviceType = 'FINEDROP-V02';
  const productionTypes = [
    deviceType,
    'FINEWAYPRO-V01'
  ]
  const emit = defineEmits(['orderCreatedDone']);
  const orderStore = useOrderStore();
  const newOrder = reactive({
    delivery_location: '',
    production_type: deviceType,
    responsible_person: localStorage.getItem('cachedResponsiblePerson') || '', // Load from localStorage
    production_quantity: 12,
    order_date: dayjs().format("YYYY-MM-DD"),
    order_deadline: ''
  });

  onMounted(async () => {
    useLoginStore().getMemberList()
  });

  watch(() => newOrder.responsible_person, (newValue) => {
    localStorage.setItem('cachedResponsiblePerson', newValue);
  });

  const addOrder = async () => {
    if (
      !newOrder.delivery_location ||
      !newOrder.production_type ||
      !newOrder.responsible_person ||
      !newOrder.production_quantity ||
      !newOrder.order_date ||
      !newOrder.order_deadline
    ) {
      alert("모든 필드를 입력해 주세요.");
      return;
    }
    try {
      await orderStore.createOrder(newOrder);
    } catch (error) {
      console.error("주문 추가에 실패했습니다.", error);
    } finally {
      emit('orderCreatedDone');
    }
  };

</script>

<style scoped>
  .container {
    z-index: 999;

  }

  .forms-inputs {
    position: relative
  }

  .form-control {
    height: 60px;
  }

  .forms-inputs span {
    position: absolute;
    top: -20px;
    left: 10px;
    background-color: #fff;
    padding: 5px 10px;
    font-size: 15px
  }

  .modal-mask {
    position: fixed;
    z-index: 12;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-content {
    position: fixed;
    z-index: 3;
    top: 0%;
    left: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .flex {
    display: flex;
  }

  .btn {
    height: 50px
  }
</style>