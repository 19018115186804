<template>
  <div class="p-4 bg-gray-100 min-h-screen h-100">
    <div class="bg-white p-6 rounded-lg shadow h-100  overflow-auto">
      <div class="fs-2 fw-bolder p-4 d-flex justify-space-between">
        주문 리스트
        <div class="text-center">
          <button @click="show = !show" class="btn btn-success px-6 py-2 rounded-lg shadow-sm text-white">
            주문 추가
          </button>
        </div>
      </div>
      <createdOrderModel v-if="show" @order-created-done="show = false" />
      <OrderDataTable v-else />
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref, reactive, watch } from 'vue';
  import OrderDataTable from '@/components/productionInventory/OrderDataTable.vue'
  import createdOrderModel from '@/components/productionInventory/CreatedOrder.vue';
  const show = ref(false)
</script>
<!-- '@/productionInventory/OrderDataTable.vue'; -->