<template>
  <div class='NavTitle'>
    <div class="DataBox">
      <div class="col-md-12 col-lg-12 col-sm-12">
        <div class="black-box">
          <div class="d-flex flex-row justify-content-between">
            <li class="col-3 ">
              <router-link class="Nav-link" to="/Fineway2/lotList">
                <div class="text-center">원재료 관리</div>
              </router-link>
            </li>
            <li class="col-3 ">
              <router-link class="Nav-link" to="/Fineway2/device">
                <div class="text-center">제품 생산 확인</div>
              </router-link>
            </li>
            <li class="col-3 ">
              <router-link class="Nav-link" to="/Fineway2/create">
                <div class="text-center">생산 일지 작성</div>
              </router-link>
            </li>
            <li class="col-3 ">
              <router-link class="Nav-link" to="/Fineway2/totalList">
                <div class="text-center">생산 관리</div>
              </router-link>
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
<style scoped>
  .text-center {
    padding: 20px;
  }

  .black-box {
    background-color: #678FFF;
  }

  li {
    padding: 0px;
    list-style: none;
  }

  .DataBox {
    background-color: #edf1f5;
    padding: 0px;
    height: 6%;
  }

  .Nav-link {
    color: #FFFFFF;
    text-decoration: none;
  }
</style>
