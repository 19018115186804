<template>
  <div class="d-flex justify-content-around text-center">
    <div class=" bg-light p-4 rounded shadow-sm w-25 mx-2">
      <h3 class="fs-3 text-muted mb-2">생산 가능 수량</h3>
      <p class="display-6 text-dark fw-bold">{{ deviceProducible }}</p>
    </div>
    <div class="bg-light p-4 rounded shadow-sm w-25 mx-2">
      <h3 class="fs-3 text-muted mb-2">제작중인 수량</h3>
      <p class="display-6 text-dark fw-bold">{{ deviceProducible }}</p>
    </div>
    <div class="bg-light  p-4 rounded shadow-sm w-25 mx-2">
      <h3 class="fs-3 text-muted mb-2">전체 주문 수량</h3>
      <p class="display-6  text-dark fw-bold">{{ totalOrderQuantity }}</p>
    </div>
  </div>
  <div class="card m-4 overflow-auto" style="height: 65%; max-height: 65%;">
    <div class="table-responsive">
      <!-- e density="compact" -->
      <v-data-table :headers="headers" :items="orderList.data" item-value="device_serial" density="compact" multi-sort
        :sort-by="headerFilter">
        <template v-slot:item.created="{ item }">
          <div class="p-1">
            <button class="btn btn-outline-primary " @click="someOtherAction(item.value._id)">
              생산완료
            </button>
          </div>

        </template>
        <template v-slot:item.delete="{ item }">
          <div class="p-1">
            <button class="btn btn-outline-danger " @click="confirmDeleteOrder(item.value._id)">
              삭제
            </button>
          </div>

        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref, reactive, watch } from 'vue';
  import dayjs from 'dayjs';
  import { useLotStore } from '@/stores/lots.js';
  import { useOrderStore } from '@/stores/order.js';
  import { useLoginStore } from '@/stores/login';
  const deviceType = 'FINEDROP-V02';
  const headerFilter = ref([
    { key: "device_complete_date", order: "asc" },
    { key: "device_io_date", order: "asc" },
  ]);
  const useLots = useLotStore();
  const orderStore = useOrderStore();
  const deviceProducible = ref(1); // 생산 가능 수량
  const totalOrderQuantity = ref(0); // 전체 주문 수량
  const orderList = reactive({
    data: []
  });
  const show = ref(false)

  const newOrder = reactive({
    delivery_location: '',
    production_type: deviceType,
    responsible_person: localStorage.getItem('cachedResponsiblePerson') || '', // Load from localStorage
    production_quantity: 12,
    order_date: dayjs().format("YYYY-MM-DD"),
    order_deadline: ''
  });

  onMounted(async () => {
    orderList.data = await orderStore.getOrderList();
    useLoginStore().getMemberList()
    deviceProducible.value = await useLots.deviceProducible(deviceType);
    totalOrderQuantity.value = orderList.data.reduce((total, order) => total + order.production_quantity, 0);
  });

  // Watch for changes in responsible_person and cache it
  watch(() => newOrder.responsible_person, (newValue) => {
    localStorage.setItem('cachedResponsiblePerson', newValue);
  });

  const addOrder = async () => {
    if (
      !newOrder.delivery_location ||
      !newOrder.production_type ||
      !newOrder.responsible_person ||
      !newOrder.production_quantity ||
      !newOrder.order_date ||
      !newOrder.order_deadline
    ) {
      alert("모든 필드를 입력해 주세요.");
      return;
    }

    try {
      const response = await orderStore.createOrder(newOrder);
      orderList.data.push(response.order); // Push the newly created order to the list
      totalOrderQuantity.value += Number(newOrder.production_quantity); // Update total order quantity
      Object.keys(newOrder).forEach(key => {
        newOrder[key] = key === 'order_date' || key === 'order_deadline' || key === 'delivery_location' ? '' : newOrder[key];
      });
    } catch (error) {
      console.error("주문 추가에 실패했습니다.", error);
    } finally {
      show.value = false
    }
  };

  const confirmDeleteOrder = async (orderId) => {
    if (confirm("이 주문을 삭제하시겠습니까?")) {
      try {
        await orderStore.deleteOrder(orderId); // 서버에서 주문 삭제
        orderList.data = orderList.data.filter(order => order._id !== orderId); // 클라이언트에서 목록 갱신
        totalOrderQuantity.value = orderList.data.reduce((total, order) => total + order.production_quantity, 0); // 전체 주문 수량 갱신
      } catch (error) {
        console.error("주문 삭제에 실패했습니다.", error);
      }
    }
  };
  const someOtherAction = async (orderId) => {
    if (confirm("이 주문을 생산완료?")) {
      try {
        await orderStore.completeOrder(orderId); // 서버에서 주문 수정

      } catch (error) {
      }
    }
  };
  const headers = [
    { title: '납품처', value: 'delivery_location' },
    { title: '장비 종류', value: 'production_type' },
    { title: '생산 단계', value: 'production_stage' },
    { title: '주문 작성자', align: 'center', value: 'responsible_person' },
    { title: '필요 생산 수', value: 'production_quantity', align: 'center' },
    { title: '현재 생산된 수', value: 'current_production_quantity', align: 'center' },
    { title: '주문 일시', value: 'order_date', align: 'center' },
    { title: '주문 마감일', value: 'order_deadline' },
    { title: '생산완료', value: 'created', sortable: false, key: 'created', align: 'center' },
    { title: '삭제', value: 'delete', sortable: false, key: 'delete', align: 'center' },
  ];
</script>
