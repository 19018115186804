import { defineStore } from "pinia";
import { axiosJWT } from "@/plugins/axiosJWT.js";
import { server } from "@/plugins/server.js";

export const useOrderStore = defineStore("order", () => {
	async function getOrderList() {
		const config = {
			method: "get",
			url: `${server}/order/orderList`,
		};
		return await axiosJWT(config)
			.then((res) => {
				return res.data?.orders;
			})
			.catch((error) => {
				console.error("Error fetching order list:", error);
				throw error;
			});
	}

	async function createOrder(order) {
		const config = {
			method: "post",
			url: `${server}/order/createOrder`,
			data: order,
		};
		return await axiosJWT(config)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {
				console.error("Error creating order:", error);
				throw error;
			});
	}

	async function completeOrder(orderId, updateData = {}) {
		const config = {
			method: "put",
			url: `${server}/order/completeOrder`, // 서버 엔드포인트 URL에 맞게 수정
			data: {
				id: orderId,
				...updateData, // 추가로 업데이트할 필드를 포함한 객체
			},
		};

		try {
			const response = await axiosJWT(config);
			return response.data;
		} catch (error) {
			console.error("Error updating order:", error);
			throw error;
		}
	}
	async function deleteOrder(id) {
		const config = {
			method: "delete",
			url: `${server}/order/deleteOrder`,
			data: { id },
		};
		return await axiosJWT(config)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {
				console.error("Error deleting order:", error);
				throw error;
			});
	}

	return {
		//action
		getOrderList,
		createOrder,
		completeOrder,
		deleteOrder,
	};
});
