import { defineStore } from "pinia";
import qs from "qs";
import { axiosJWT } from "@/plugins/axiosJWT.js";
import { server } from "@/plugins/server.js";

export const useLotStore = defineStore("lots", () => {
	let lotData = {};
	// lot List 전체 갱신
	async function getLotList(payload) {
		const configData = qs.stringify({
			deviceType: payload.deviceType,
			history: true,
		});
		const config = {
			method: "get",
			url: `${server}/lot/lotList?${configData}`,
		};
		const a = await axiosJWT(config).then((res) => {
			return res.data.lot.lot_lists;
		});
		this.lotData = a;
		return a;
	}

	async function failLot(payload) {
		const configData = {
			lot_serial: payload.deviceSerial,
		};
		const config = {
			method: "put",
			url: `${server}/lot/failLot`,
			data: configData,
		};
		console.log(
			await axiosJWT(config)
				.then((res) => {
					return res.data.message;
				})
				.catch((err) => {
					return err.response.data.message;
				})
		);
	}
	async function useLot(payload) {
		const configData = {
			lot_serial: payload.deviceSerial,
		};
		const config = {
			method: "put",
			url: `${server}/lot/useLot`,
			data: configData,
		};
		console.log(
			await axiosJWT(config)
				.then((res) => {
					return res.data.message;
				})
				.catch((err) => {
					return err.response.data.message;
				})
		);
	}
	async function changeTotalLot(payload) {
		const configData = {
			lot_serial: payload.lot_serial,
			lot_total: payload.lot_total,
		};
		const config = {
			method: "put",
			url: `${server}/lot/changeTotal`,
			data: configData,
		};
		console.log(
			await axiosJWT(config)
				.then((res) => {
					return res.data.message;
				})
				.catch((err) => {
					return err.response.data.message;
				})
		);
	}
	// lot 생성 api
	async function createLot(payload) {
		const configData = {
			lot_device_type: payload.deviceType,
			lot_name: payload.name,
			lot_type: payload.type,
			lot_serial: payload.serial,
			lot_total: payload.total,
		};
		console.log(configData);
		const config = {
			method: "post",
			data: configData,
			url: `${server}/lot/create`,
		};
		await axiosJWT(config);
	}
	async function deviceProducible(deviceType) {
		const config = {
			method: "get",
			url: `${server}/lot/deviceProducible?deviceType=${deviceType}`,
		};
		const res = await axiosJWT(config);
		return res.data.producibleCount;
	}
	return {
		createLot,
		deviceProducible,
		getLotList,
		lotData,
		failLot,
		useLot,
		changeTotalLot,
	};
});
